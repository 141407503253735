import Geosuggest from '@ubilabs/react-geosuggest';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import RouteUtils from '../../../modules/routeUtils';
import GoogleMapsUtils from '../../../modules/googleMapsUtils';

class LocationSearch extends Component {

  constructor(props, _railsContext) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitParent = this.onSubmitParent.bind(this);
    this.onSuggestSelect = this.onSuggestSelect.bind(this);

    this.state = {
      value: null,
      isMapsLoaded: false,
    }
  }

  componentDidMount() {
    const { location } = RouteUtils.getQueryParams(this.props.location);

    GoogleMapsUtils.importLibraries(['places', 'geocoding']).then(() => {

      this.setState({
        isMapsLoaded: true,
      });

      this.onChange(location);
      if (location && this.geosuggest) {
        this.geosuggest.update(location);
      }
    });
  }

  componentDidUpdate(prevProps) {
    const oldParams = RouteUtils.getQueryParams(prevProps.location);
    const newParams = RouteUtils.getQueryParams(this.props.location);

    if (oldParams.location !== newParams.location) {
      this.onChange(newParams.location);

      if (newParams.location) {
        this.geosuggest.update(newParams.location);
      } else {
        this.onClear();
      }
    }
  }

  onChange(value, callback = null) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }

    this.setState({ value }, callback);
  }

  onClear() {
    this.geosuggest.clear();
    this.onChange(null, this.props.showButton ? null : this.onSubmit);
  }

  onKeyUp(e) {
    if (e.key === 'Enter') {
      if (this.props.showButton) {
        this.onSubmit();
      } else {
        this.onSubmitParent();
      }
    }
  }

  onSubmit() {
    if (this.props.noSubmit) { return; }

    const push = !window.location.pathname.startsWith('/catering/');
    const { day } = RouteUtils.getQueryParams(this.props.location);
    let params = { location: this.state.value };

    if (!day && this.state.value) {
      params.day = 'today';
    }

    RouteUtils.updateQueryParam(
      this.props.history,
      this.props.location,
      params,
      '/search',
      push
    );
  }

  onSubmitParent() {
    if (this.props.noSubmit) { return; }

    this.props.onSubmit();
  }

  onSuggestSelect({ label, placeId }) {
    const doSubmit = !this.props.showButton && placeId;
    this.onChange(label, doSubmit ? this.onSubmit : null);
  }

  renderSearch() {
    return (
      <div className="Search Search--location">
        <img src="/static/images/pin.svg" alt="Map Marker" height='18' width='14' />

        <Geosuggest
          placeholder="Enter your location..."
          types={['address']}
          ref={(c) => { this.geosuggest = c; }}
          onSuggestSelect={this.onSuggestSelect}
          onChange={this.onChange}
          onKeyPress={this.onKeyUp}
        />

        {!!this.state.value && (
          <span className="Search__Clear" onClick={this.onClear}>×</span>
        )}
      </div>
    );
  }

  render() {
    if (!this.state.isMapsLoaded) return null;
    const { cityConfig, showButton } = this.props;

    if (showButton) {
      return (
        <div className={showButton ? 'Search--location-wrapper' : ''}>
          {this.renderSearch()}
          <button
            className="Button"
            disabled={!this.state.value}
            onClick={this.onSubmit}
          >
            Find {cityConfig.vehicle_type.toLowerCase()}s!
          </button>
        </div>
      );
    }

    return this.renderSearch();
  }
}

LocationSearch.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  showButtton: PropTypes.bool,
};

export default withRouter(LocationSearch);
