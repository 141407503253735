import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// Components
import Tippy from '@tippyjs/react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import PureLoader from './PureLoader';

// Actions
import notificationActions from '../../actions/notificationActions';
import { getAllNotifications } from '../../selectors';

// Misc
import { throttle } from '../../modules/helpers';

import '../../../stylesheets/shared/components/Tippy.postcss';
import styles from '../public/nav/Header.module.postcss';

dayjs.extend(relativeTime);

class HeaderNotifications extends Component {

  constructor(props) {
    super(props);
    this.clearAllNotifications = this.clearAllNotifications.bind(this);
    this.fetchNotifications = throttle(this.fetchNotifications.bind(this), 500);
    this.recheckInterval = null;
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    const { noFetch } = this.props;
    this.fetchNotifications();

    if (!noFetch) {
      this.recheckInterval = setInterval(() => {
        this.fetchNotifications();
      }, 30000);
    }
  }

  componentWillUnmount() {
    if (this.recheckInterval) {
      clearInterval(this.recheckInterval);
    }
  }

  getParams() {
    const { notifications, loading } = this.props;
    const { open } = this.state;
    const displayedNotifications = this.displayedNotifications(notifications);
    return {
      notifications,
      open,
      displayedNotifications,
      loading
    };
  }

  displayedNotifications(notifications) {
    const { auth: { userData }, truck, truckSlug } = this.props;

    return notifications.map((notification) => {
      let link = '';
      let content = notification.content;
      if ((truck || truckSlug) && notification.notifiable_type === "Message") {
        if (notification.truck_slug) {
          link = `/vendor/truck/${notification.truck_slug}`;
        } else if (truckSlug) {
          link = `/vendor/truck/${truckSlug}`;
        } else {
          link = `/vendor/truck/${truck.id}`;
        }
        content = (
          <div>{notification.content}:<br/><div dangerouslySetInnerHTML={{ __html: notification.message }}/></div>
        );
      } else if (userData.catering_user) {
        link = `/catering/requests/${notification.catering_request_id}`;
        link += `?bid=${notification.commentable_id}#${notification.notifiable_id}`;
      } else {
        link = `/vendor/truck/${notification.truck_id}`;
        link += `/catering-requests/${notification.catering_request_id}#${notification.notifiable_id}`;
      }
      return (
        <Link
          to={link}
          key={notification.id}
          className={styles.HeaderNotification}
        >
          <div className={styles.HeaderNotification_date}>
            {dayjs(notification.created_at).fromNow()}
          </div>
          <div className={styles.HeaderNotification_content}>
            {content}
          </div>
        </Link>
      );
    });
  }

  clearAllNotifications() {
    this.props.clearAllNotifications({ user_id: this.props.auth.userData.id });
  }

  fetchNotifications() {
    this.props.fetchNotifications({ user_id: this.props.auth.userData.id });
  }

  render() {
    const { displayedNotifications, notifications, open, loading } = this.getParams();

    return (
      <div
        className={cn({
          [styles.HeaderNotifications]: true,
          [styles.HeaderNotifications__open]: open,
        })}
      >
        <Tippy
          placement='bottom-end'
          trigger='click'
          theme='light'
          appendTo={() => document.body}
          onShow={() => this.setState({ open: true })}
          onHide={() => this.setState({ open: false })}
          interactive
          content={(
            <div className={styles.HeaderNotifications_popUp}>
              <div className={styles.HeaderNotifications_popUpContent}>
                <div className={styles.HeaderNotifications_header}>
                  <h5 className="u-mb0">
                    Activity
                  </h5>
                  <PureLoader
                    small
                    entities="notification"
                    actions="fetch"
                    loading={loading}
                  >
                    {displayedNotifications.length > 0 &&
                      <button
                        className="Button Button--small u-m0"
                        onClick={this.clearAllNotifications}
                      >
                        Clear all
                      </button>
                    }
                  </PureLoader>
                </div>
                <div>
                  {displayedNotifications}
                  {displayedNotifications.length === 0 &&
                    <span>
                      No unread activity
                    </span>
                  }
                </div>
              </div>
            </div>
          )}
        >
          <button className={styles.HeaderNotifications_bell}>
            <SVG src='/static/images/icons/notification.svg'/>
            {notifications.length > 0 &&
              <span className={styles.HeaderNotifications_count + " HeaderNotifications-count"}>
                {notifications.length}
              </span>
            }
          </button>
        </Tippy>
      </div>
    );
  }
}

HeaderNotifications.propTypes = {
  clearAllNotifications: PropTypes.func,
  fetchNotifications: PropTypes.func,
  auth: PropTypes.object,
  notifications: PropTypes.array,
  admin: PropTypes.bool,
};

HeaderNotifications.defaultProps = {
  admin: false
};

function mapStateToProps(state, props) {
  return {
    auth: state.auth,
    loading: state.ui.loading,
    notifications: getAllNotifications(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchNotifications(params) {
      return dispatch(notificationActions.fetch(params));
    },
    clearAllNotifications(params) {
      return dispatch(notificationActions.clearAll(params));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNotifications);

