import param from 'jquery-param';
import { normalize } from 'normalizr';

// Misc Requirements
import API from '../modules/api';
import baseActionsFor from './baseActions';
import asyncActionsFor from './asyncActions';
import { notificationSchema, notificationListSchema } from './schemas';
import { displayError } from './errorActions';

// Create Base Actions
const baseActions = baseActionsFor('notifications', 'notification');

// Create Base Async Actions
const asyncActions = asyncActionsFor({
  baseActions,
  resourceName: 'notification',
  listName: 'notifications',
  resourceSchema: notificationSchema,
  listSchema: notificationListSchema
});

// Custom Actions and Base Action Overrides
const customActions = {
  fetch(params) {
    return dispatch => {
      dispatch(baseActions.fetchStart());
      const promise = API.get(`/api/users/${params.user_id}/notifications?${param(params)}`);
      promise.then((response) => {
        const records = normalize(response, notificationListSchema);
        dispatch(baseActions.fetchSuccess(records));
      });

      // Let's fail silently
      // promise.catch((response) => {
      //   dispatch(baseActions.fetchError(response));
      //   if (response.status === 404) {
      //     dispatch(displayError(404, response.statusText));
      //   } else {
      //     dispatch(displayError(500, response.statusText, true));
      //   }
      // });

      return promise;
    };
  },

  clearAll(params) {
    return dispatch => {
      dispatch(baseActions.fetchStart());
      const promise = API.post(`/api/users/${params.user_id}/notifications/clear_all?${param(params)}`);

      promise.then((response) => {
        const records = normalize(response, notificationListSchema);
        dispatch(baseActions.fetchSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.fetchError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  },

  clearOne(params) {
    return dispatch => {
      dispatch(baseActions.fetchStart());
      const promise = API.post(`/api/users/${params.user_id}/notifications/clear?${param(params)}`);

      promise.then((response) => {
        const records = normalize(response, notificationListSchema);
        dispatch(baseActions.fetchSuccess(records));
      });

      promise.catch((response) => {
        dispatch(baseActions.fetchError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText));
        } else {
          dispatch(displayError(500, response.statusText, true));
        }
      });

      return promise;
    };
  }
};

// Merge Actions
const actions = Object.assign(baseActions, asyncActions, customActions);

// Export
export default actions;
