import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { upperFirst } from '../../modules/helpers';

import styles from './Loader.module.postcss';

// This version of Loader saves render time by not being connected via Redux.
class PureLoader extends Component {
  render() {
    const { loading, entities, actions, padded, center, small, classes, loaderClasses, containerClasses,
            children, show, overlay, overlayScreen, disable } = this.props;

    let entitiesLoading = false;
    let entitiesList = entities.replace(/\s+/g, "").split(',');
    let actionsList = actions.replace(/\s+/g, "");
    entitiesList.forEach((entity) => {
      actionsList.split(',').forEach((action) => {
        if (loading && loading[`${entity}${upperFirst(action)}`]) entitiesLoading = true;
      });
    });


    if (disable || (!entitiesLoading && !show)) {
      return (
        <div
          className={cn(classes, {
            [styles.LoaderContent]: true
          })}
        >
          {children}
        </div>
      );
    }

    if (overlay) {
      return (
        <div
          className={cn(containerClasses, {
            [styles.Loader_container]: true,
            [styles.Loader_container__screen]: overlayScreen
          })}
        >
          <div
            className={cn({
              [styles.Loader]: true,
              [styles.Loader__overlay]: true,
              [styles.Loader__padded]: padded
            })}
          >
            Loading
          </div>
          {overlayScreen &&
            <div className={styles.Loader_screen}>
              {children}
            </div>
          }
        </div>
      );
    }

    if (padded) {
      return (
        <div
          className={cn(containerClasses, {
            [styles.Loader_container]: true,
            [styles.Loader_container__padded]: padded,
          })}
        >
          <div
            className={cn(loaderClasses, {
              [styles.Loader]: true,
              [styles.Loader__center]: center,
              [styles.Loader__small]: small,
            })}
          >
            Loading
          </div>
        </div>
      );
    }

    return (
      <div
        className={cn(loaderClasses, {
          [styles.Loader]: true,
          [styles.Loader__center]: center,
          [styles.Loader__padded]: padded,
          [styles.Loader__small]: small,
        })}
      >
        Loading
      </div>
    );
  }
}

PureLoader.propTypes = {
  entities: PropTypes.string,
  actions: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool,
  center: PropTypes.bool,
  loading: PropTypes.object,
  overlay: PropTypes.bool,
  overlayScreen: PropTypes.bool,
  padded: PropTypes.bool,
  small: PropTypes.bool,
  classes: PropTypes.string
};

PureLoader.defaultProps = {
  show: false,
  padded: false,
  overlay: false,
  center: false,
  small: false,
  overlayScreen: false,
  entities: '',
  actions: ''
};

export default PureLoader;
