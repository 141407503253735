import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import RouteUtils from '../../../modules/routeUtils';

class TermSearch extends Component {

  constructor(props, _railsContext) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitParent = this.onSubmitParent.bind(this);
    this.renderSearch = this.renderSearch.bind(this);

    this.state = {
      value: null,
    }
  }

  componentDidMount() {
    const { query } = RouteUtils.getQueryParams(this.props.location);
    this.onChange(query);
  }

  componentDidUpdate(prevProps) {
    const oldParams = RouteUtils.getQueryParams(prevProps.location);
    const newParams = RouteUtils.getQueryParams(this.props.location);

    if (oldParams.query !== newParams.query) {
      this.onChange(newParams.query);
    }
  }

  onChange(value, callback = null) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }

    this.setState({ value }, callback);
  }

  onClear() {
    this.onChange(null, this.onSubmit);
  }

  onKeyUp(e) {
    if (e.key === 'Enter') {
      if (this.props.showButton) {
        this.onSubmit();
      } else {
        this.onSubmitParent();
      }
    }
  }

  onSubmit() {
    if (this.props.noSubmit) { return; }

    RouteUtils.updateQueryParam(
      this.props.history,
      this.props.location,
      { query: this.state.value },
      '/search',
    );
  }

  onSubmitParent() {
    if (this.props.noSubmit) { return; }

    this.props.onSubmit();
  }

  renderSearch() {
    return (
      <div className="Search Search--term">
        <img src="/static/images/search.svg" alt="Search" width='15' height='16' />
        <input
          onChange={(e) => this.onChange(e.target.value)}
          onKeyUp={this.onKeyUp.bind(this)}
          placeholder="Search by name or cuisine..."
          value={this.state.value || ''}
        />
        {!!this.state.value && (
          <span onClick={this.onClear.bind(this)}>×</span>
        )}
      </div>
    );
  }

  render() {
    const { cityConfig, showButton } = this.props;

    if (showButton) {
      return (
        <div className={showButton ? 'Search--location-wrapper' : ''}>
          {this.renderSearch()}
          <button
            className="Button"
            // disabled={!this.state.value}
            onClick={this.onSubmit}
          >
            Find {cityConfig.vehicle_type.toLowerCase()}s!
          </button>
        </div>
      );
    }

    return this.renderSearch();
  }
}

TermSearch.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default withRouter(TermSearch);
