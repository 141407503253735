import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Header.module.postcss';

const SEATTLE_STOPS = [
  { name: "Westlake Park", link: "/schedule/westlake-park"},
  { name: "The Polyclinic", link: "/schedule/the-polyclinic-madison-center"},
  { name: "Starbucks Center", link: "/schedule/starbucks-center"},
  { name: "Villa Marina", link: "/schedule/villa-marina"},
  { name: "T-Mobile Campus", link: "/schedule/t-mobile-campus-factoria"},
  { name: "Rooftop Brewing", link: "/schedule/rooftop-brewing-company"},
  { name: "Chuck's Hop Shop", link: "/schedule/chuck-s-hop-shop-greenwood"},
  { name: "Boeing Campus", link: "/schedule/boeing-campus"},
  { name: "Urban Family Brewing", link: "/schedule/urban-family-brewing-co"},
  { name: "Broadview Tap House", link: "/schedule/broadview-tap-house"},
  { name: "Saleh's", link: "/schedule/salehs"},
];

const PORTLAND_STOPS = [
  { name: "Cartopia", link: "/schedule/cartopia-food-cart-pod"},
  { name: "Pioneer Courthouse Square", link: "/schedule/pioneer-courthouse-square-food-cart-pod"},
  { name: "Cartlandia", link: "/schedule/cartlandia-food-cart-pod"},
  { name: "Hawthorne Asylum", link: "/schedule/hawthorne-asylum"},
  { name: "Fifth Avenue Food Cart Pod", link: "/schedule/fifth-avenue-food-cart-pod"},
  { name: "St Johns Food & Beer Porch", link: "/schedule/st-johns-food-beer-porch"},
  { name: "Portland Mercado", link: "/schedule/portland-mercado"},
  { name: "Third Avenue Food Cart Pod", link: "/schedule/third-avenue-food-cart-pod"},
  { name: "Portland State University", link: "/schedule/portland-state-university-food-cart-pod"},
  { name: "Prost Marketplace", link: "/schedule/prost-marketplace"},
];

const SEATTLE_NEIGHBORHOODS = [
  { name: "South Lake Union", link: "/search?day=today&location=south-lake-union"},
  { name: "Bellevue", link: "/search?day=today&location=bellevue"},
  { name: "Breweries", link: "/search?day=today&location=breweries"},
  { name: "SoDo", link: "/search?day=today&location=sodo"},
  { name: "Maple Valley", link: "/search?day=today&location=maple-valley"},
  { name: "Ballard", link: "/search?day=today&location=ballard"},
  { name: "Pioneer Square", link: "/search?day=today&location=pioneer-square"},
  { name: "Downtown", link: "/search?day=today&location=downtown"},
  { name: "Redmond", link: "/search?day=today&location=redmond"},
  { name: "Kirkland", link: "/search?day=today&location=kirkland"},
  { name: "West Seattle", link: "/search?day=today&location=west-seattle"},
];

const PORTLAND_NEIGHBORHOODS = [
  { name: "Downtown", link: "/search?day=today&location=downtown"},
  { name: "Hawthorne", link: "/search?day=today&location=hawthorne"},
  { name: "China Town/Old Town", link: "/search?day=today&location=old-town-chinatown"},
  { name: "Mississippi District", link: "/search?day=today&location=mississippi-district"},
  { name: "Nob Hill", link: "/search?day=today&location=nob-hill"},
  { name: "St. Johns", link: "/search?day=today&location=north-portland-st-johns"},
  { name: "Foster-Powell", link: "/search?day=today&location=foster-powell"},
  { name: "Mount Tabor", link: "/search?day=today&location=mt-tabor"},
  { name: "Rose City Park", link: "/search?day=today&location=rose-city-park-northeast-portland"},
  { name: "Creston – Kenilworth", link: "/search?day=today&location=creston-kenilworth-southeast-portland"},
];

const SEATTLE_TRUCKS = [
  { name: "Poke To The Max", link: "/food-trucks/sam-choy-s-poke-to-the-max"},
  { name: "Theo's Gyros", link: "/food-trucks/theo-s-gyros"},
  { name: "Incredibowls", link: "/food-trucks/incredibowls"},
  { name: "The Roll Pod", link: "/food-trucks/the-roll-pod"},
  { name: "Where Ya At Matt", link: "/food-trucks/where-ya-at-matt"},
  { name: "Snout & Co.", link: "/food-trucks/snout-and-co"},
  { name: "NOSH", link: "/food-trucks/nosh"},
  { name: "Kaosamai", link: "/food-trucks/kaosamai"},
  { name: "Wood Shop BBQ", link: "/food-trucks/wood-shop-bbq"},
  { name: "Tat's Truck", link: "/food-trucks/tat-s-truck"},
  { name: "Naansense", link: "/food-trucks/naansense"},
];

const PORTLAND_TRUCKS = [
  { name: "Matt's BBQ", link: "/food-trucks/matt-s-bbq"},
  { name: "Jojo Food Cart", link: "/food-trucks/jojo-food-cart"},
  { name: "Pyro Pizza", link: "/food-trucks/pyro-pizza"},
  { name: "Chicken and Guns", link: "/food-trucks/chicken-and-guns"},
  { name: "MATTA", link: "/food-trucks/matta"},
  { name: "Churros Locos", link: "/food-trucks/churros-locos"},
  { name: "Kim Jong Grillin", link: "/food-trucks/kim-jong-grillin"},
  { name: "Koi Fusion", link: "/food-trucks/koi-fusion"},
  { name: "Wolf and Bear’s", link: "/food-trucks/wolf-and-bear-s"},
  { name: "Yoshi’s Sushi", link: "/food-trucks/yoshi-s-sushi"},
];

const NearMeLink = (props) => {
  return (
    <li>
      <Link to={props.link}>
        {props.name}
      </Link>
    </li>
  );
}

const NearMeContent = (props) => {

  let popularStops = SEATTLE_STOPS;
  let poularNeighborhoods = SEATTLE_NEIGHBORHOODS;
  let popularTrucks = SEATTLE_TRUCKS;
  let stopsTitle = "Most popular food truck stops";
  if (props.cityConfig.city_name === "Portland") {
    popularStops = PORTLAND_STOPS;
    poularNeighborhoods = PORTLAND_NEIGHBORHOODS;
    popularTrucks = PORTLAND_TRUCKS;
    stopsTitle = "Most popular food cart pods";
  }

  const stopList = popularStops.map((stop, i) => {
    return (<NearMeLink key={"stop-" + i} name={stop.name} link={stop.link}/>);
  });
  const neighborhoodList = poularNeighborhoods.map((neighborhood, i) => {
    return (<NearMeLink key={"neighborhood-" + i} name={neighborhood.name} link={neighborhood.link}/>);
  });
  const truckList = popularTrucks.map((truck, i) => {
    return (<NearMeLink key={"truck-" + i} name={truck.name} link={truck.link}/>);
  });

  const vehicleType = props.cityConfig.vehicle_type.toLowerCase();

  return (
    <div className={styles.NearMeContent + " u-grid-row"}>
      <div className="u-grid-col u-grid-md-quarter">
        <strong>Best food {vehicleType}s near me</strong>
        <ul>
          <li>
            <Link to={'/search?query=asian'}>
              &#129377; Asian food {vehicleType}s near me
            </Link>
          </li>
          <li>
            <Link to={'/search?query=taco'}>
              &#127790; Taco food {vehicleType}s near me
            </Link>
          </li>
          <li>
            <Link to={'/search?query=burgers'}>
              &#127828; Burger food {vehicleType}s near me
            </Link>
          </li>
          <li>
            <Link to={'/search?query=dessert'}>
              &#127846; Dessert food {vehicleType}s near me
            </Link>
          </li>
          <li>
            <Link to={'/search?query=bbq'}>
              &#128022; BBQ food {vehicleType}s near me
            </Link>
          </li>
        </ul>
      </div>
      <div className="u-grid-col u-grid-md-quarter">
        <strong>{stopsTitle}</strong>
        <ul>
          {stopList}
        </ul>
      </div>
      <div className="u-grid-col u-grid-md-quarter">
        <strong>Most popular neighborhoods</strong>
        <ul>
          {neighborhoodList}
        </ul>
      </div>
      <div className="u-grid-col u-grid-md-quarter">
        <strong>Top searched food {vehicleType}s</strong>
        <ul>
          {truckList}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(NearMeContent);
