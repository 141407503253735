import queryString from 'query-string';
import { pickBy } from './helpers';

const RouteUtils = {
  getQueryParams(location) {
    return queryString.parse(location.search);
  },

  updateQueryParam(history, location, newParams, pathname) {
    const currentParams = RouteUtils.getQueryParams(location);

    let params = Object.assign({}, currentParams, newParams);
    params = pickBy(params);

    history.push({
      pathname: pathname || history.location.pathname,
      search: queryString.stringify(params)
    });
  },
};

export default RouteUtils;
