import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';

import RouteUtils from '../../../modules/routeUtils';

// Components
import LocationSearch from './LocationSearch';
import TermSearch from './TermSearch';

import styles from './Header.module.postcss';

class CombinedSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: null,
      query: null,
    };
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  onSubmit() {
    RouteUtils.updateQueryParam(
      this.props.history,
      this.props.location,
      this.state,
      '/search'
    );
  }

  render() {
    const { cityConfig } = this.props;

    return (
      <div className={cn(styles.CombinedSearch, this.props.className)}>
        <LocationSearch
          noSubmit={!this.props.hideButton}
          onChange={this.onChange.bind(this, 'location')}
          onSubmit={this.onSubmit.bind(this)}
          cityConfig={cityConfig}
        />
        <TermSearch
          noSubmit={!this.props.hideButton}
          onChange={this.onChange.bind(this, 'query')}
          onSubmit={this.onSubmit.bind(this)}
        />
        {!this.props.hideButton &&
          <React.Fragment>
            <button
              className="Button u-mt05"
              onClick={this.onSubmit.bind(this)}
            >
              Find {cityConfig.vehicle_type.toLowerCase()}s!
            </button>
            <a
              href="/search"
              className={styles.CombinedSearch_resetButton + " Button"}
            >
              Reset search
            </a>
          </React.Fragment>
        }
      </div>
    );
  }
}

CombinedSearch.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  hideButton: PropTypes.bool,
};

export default withRouter(CombinedSearch);
