import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import Tippy from '@tippyjs/react';

// Components
import { Link } from 'react-router-dom';
import CombinedSearch from './CombinedSearch';
import NearMeDropdown from './NearMeDropdown';
import HeaderSubNav from './HeaderSubNav';
import NearMeContent from './NearMeContent';
import HeaderNavControls from './HeaderNavControls';
import Notifications from '../../shared/Notifications';

// Actions
import { userLogout } from '../../../actions/authActions';

// Selectors
import { getCityConfig } from '../../../selectors';

// Misc
import detectMobile from '../../../hoc/detectMobile';
import { fixBodyScroll, unfixBodyScroll } from '../../../modules/helpers';
import '../../../../stylesheets/shared/components/Tippy.postcss';
import styles from './Header.module.postcss';

class Header extends Component {
  constructor(props, _railsContext) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.closeSubNavs = this.closeSubNavs.bind(this);
    this.openSubNav = this.openSubNav.bind(this);
    this.openServicesSubNav = this.openServicesSubNav.bind(this);

    this.logoImg = (
      <img src={props.cityConfig.logo} alt={props.cityConfig.site_title} width='180' height='30' />
    );
    this.servicesIcon = (
      <img
        className={styles.headerMenuIcon}
        src='/static/images/admin/navigation/food.svg'
        width='64'
        height='64'
        alt='Food Categories'
      />
    );
    this.cateringLinkText = (
      <React.Fragment>
        <img
            className={styles.headerMenuIcon}
            src='/static/images/admin/navigation/catering.svg'
            width='64'
            height='64'
            alt='Catering Requests'
          />
          Catering
      </React.Fragment>
    );

    this.state = {
      mobileMenuOpen: false,
      mobileSearchOpen: false,
      nearMeSubNavOpen: false,
      servicesSubNavOpen: false,
      scrollY: 0,
    };

    this.cateringUser = false;
    if (props.auth.userData) {
      this.cateringUser = props.auth.userData.catering_user;
    }
    this.pushLinks = !props.admin && !this.cateringUser;

    this._NearMeContent = <NearMeContent cityConfig={props.cityConfig} />;
    this._servicesLinks = (
      <div className={cn(styles.GlobalHeader_dropdownLinks, styles.ServicesDropdown_inner)}>
        <Link
          to="/services/office-parks"
          className={styles.GlobalHeader_navLink}

        >
          Offices
        </Link>
        <Link
          to="/services/residential"
          className={styles.GlobalHeader_navLink}

        >
          Residential
        </Link>
        { props.cityConfig.city_name !== "Portland" &&
          <Link
            to="/services/breweries"
            className={styles.GlobalHeader_navLink}

          >
            Breweries
          </Link>
        }
      </div>
    );
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      document.body.style = '';
      this.setState({
        mobileMenuOpen: false,
        mobileSearchOpen: false,
        nearMeSubNavOpen: false,
        servicesSubNavOpen: false,
      });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  toggleMenu() {
    const { mobileMenuOpen, mobileSearchOpen, scrollY } = this.state;

    let newScrollY = 0;
    if (mobileMenuOpen && !mobileSearchOpen) {
      unfixBodyScroll(scrollY);
    } else if (!mobileMenuOpen && !mobileSearchOpen) {
      newScrollY = fixBodyScroll();
    }

    this.setState({
      mobileMenuOpen: !mobileMenuOpen,
      mobileSearchOpen: !mobileMenuOpen ? false : mobileSearchOpen,
      scrollY: !mobileMenuOpen && !mobileSearchOpen ? newScrollY : scrollY,
    });
  }

  toggleSearch() {
    const { mobileMenuOpen, mobileSearchOpen, scrollY } = this.state;

    let newScrollY = 0;
    if (mobileSearchOpen && !mobileMenuOpen) {
      unfixBodyScroll(scrollY);
    } else if (!mobileSearchOpen && !mobileMenuOpen) {
      newScrollY = fixBodyScroll();
    }

    this.setState({
      mobileMenuOpen: !mobileSearchOpen ? false : mobileMenuOpen,
      mobileSearchOpen: !mobileSearchOpen,
      scrollY: !mobileSearchOpen && !mobileMenuOpen ? newScrollY : scrollY,
    });
  }

  openSubNav() {
    this.setState({ nearMeSubNavOpen: true });
  }

  openServicesSubNav() {
    this.setState({ servicesSubNavOpen: true });
  }

  closeSubNavs() {
    this.setState({
      nearMeSubNavOpen: false,
      servicesSubNavOpen: false,
    })
  }

  render() {
    const { auth, admin, location, logout, cityConfig, isMobile } = this.props;
    const { mobileMenuOpen, mobileSearchOpen, servicesSubNavOpen, nearMeSubNavOpen } = this.state;

    return (
      <header
        className={cn({
          [styles.GlobalHeader]: true,
          [styles.GlobalHeader__menuOpen]: mobileMenuOpen,
          [styles.GlobalHeader__searchOpen]: mobileSearchOpen,
        })}
      >
        <div className="Container">
          <button
            className={styles.headerMenuToggle + " u-mobileOnly"}
            onClick={this.toggleMenu}
          >
            { !mobileMenuOpen && <img src="/static/images/admin/navigation/menu.svg" alt="Toggle Menu" width='16' height='9' />}
            { mobileMenuOpen && <img src="/static/images/admin/x-black.svg" alt="Toggle Menu" />}
          </button>

          <Link
            to="/"
            className={styles.GlobalHeader_logo}
          >
            {this.logoImg}
          </Link>

          <div className="u-mobileOnly">
            {auth.loggedIn &&
              <Notifications
                admin={admin}
              />
            }
            {!auth.loggedIn &&
              <Link
                className="Button PlainButton Button--small"
                to="/login"
              >
                Log In
              </Link>
            }
          </div>

          <button
            className={styles.headerMenuToggle + " u-mobileOnly"}
            onClick={this.toggleSearch}
          >
            { !mobileSearchOpen && <img src="/static/images/admin/search-black.svg" alt="Toggle Search" width='17' height='18'/>}
            { mobileSearchOpen && <img src="/static/images/admin/x-black.svg" alt="Toggle Search" width='18' height='18' />}
          </button>

          { !isMobile &&
            <CombinedSearch
              className={isMobile ? styles.GlobalHeader_search2 : styles.GlobalHeader_search}
              cityConfig={cityConfig}
              hideButton
            />
          }

          { isMobile &&
            <div className={styles.GlobalHeader_search2}>
              <CombinedSearch cityConfig={cityConfig} />
            </div>
          }

          <nav className={styles.GlobalHeader_nav}>
            <NearMeDropdown
              admin={admin}
              cityConfig={cityConfig}
              onMobileClick={this.openSubNav}
            />

            <Tippy
              placement='bottom-start'
              theme='light'
              arrow={false}
              animation='scale-subtle'
              appendTo={() => document.body}
              interactive
              content={this._servicesLinks}
            >
              <span className={styles.GlobalHeader_navLink + " u-noMobileInlineBlock"}>
                <Link
                  to="/services"
                >
                  Services
                </Link>
              </span>
            </Tippy>

            <button
              type="button"
              className={styles.GlobalHeader_navLink + " u-mobileOnly"}
              onClick={this.openServicesSubNav}
            >
              {this.servicesIcon}
              Services
            </button>

            <Link
              className={styles.GlobalHeader_navLink}
              to="/catering"
            >
              {this.cateringLinkText}
            </Link>

            <HeaderNavControls
              auth={auth}
              admin={admin}
              onLogout={logout}
              cityConfig={cityConfig}
            />
          </nav>

          <HeaderSubNav
            active={nearMeSubNavOpen && mobileMenuOpen}
            onClose={this.closeSubNavs}
          >
            {this._NearMeContent}
          </HeaderSubNav>

          <HeaderSubNav
            active={servicesSubNavOpen && mobileMenuOpen}
            onClose={this.closeSubNavs}
          >
            {this._servicesLinks}
          </HeaderSubNav>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  admin: PropTypes.bool,
  auth: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  logout: PropTypes.func,
  cityConfig: PropTypes.object,
};

Header.defaultProps = {
  admin: false
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout() {
      dispatch(userLogout());
    }
  };
}

const detectMobileComponent = detectMobile(Header);
export default connect(mapStateToProps, mapDispatchToProps)(detectMobileComponent);
