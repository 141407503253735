import React, { Component } from 'react';
import { isMobile } from '../modules/helpers';

function detectMobile(WrappedComponent) {
  class DetectMobile extends Component {
    constructor(props) {
      super(props);
      this.update = this.update.bind(this);
      this.isMobile = isMobile();

      this.state = {
        isMobile: this.isMobile
      }
    }

    componentDidMount() {
      window.addEventListener('resize', this.update);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.update);
    }

    update() {
      this.setState({ isMobile: isMobile() });
    }

    render() {
      return (
        <WrappedComponent
          isMobile={this.state.isMobile}
          {...this.props}
        />
      );
    }
  }

  return DetectMobile;
}

export default detectMobile;
